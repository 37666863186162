const CryptoJS = require("crypto-js")
const defaultKey = process.env.REACT_APP_CLIENT_KEY
const defaultIV = process.env.REACT_APP_CLIENT_IV

export function createGuid() {
  function s4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-4" +
    s4().substr(0, 3) +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  ).toLowerCase()
}

export const generateHeader = () => {
  let dateToUse = new Date()
  let UTCTimestamp = dateToUse.toISOString().replace("Z", "")

  let dateInToken = UTCTimestamp.replace("T", "")
    .replace(":", "")
    .replace(":", "")
    .substring(0, UTCTimestamp.length - 7)
  let shaOneEncrypt = CryptoJS.SHA512(dateInToken + process.env.REACT_APP_CLIENT_ID)

  const apiHeader = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    "x-correlation-id": createGuid(),
    "x-token": shaOneEncrypt.toString(CryptoJS.enc.Hex),
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_SUBSCRIPTION_KEY,
    "Ocp-Apim-Trace": true,
    UTCTimestamp: UTCTimestamp,
    "Cross-Origin-Opener-Policy": "same-origin",
    "X-Frame-Options": "SAMEORIGIN",
    "X-Content-Type-Options": "nosniff",
    "Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
  }

  return apiHeader
}

//HEX Encryption
export const encrypt = (value, token = defaultKey, vector = defaultIV) => {
  const key = CryptoJS.enc.Utf8.parse(token)
  const iv = CryptoJS.enc.Utf8.parse(vector)
  if (typeof value === "object") {
    value = JSON.stringify(value)
  }
  const encryptedData = CryptoJS.AES.encrypt(value, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).ciphertext.toString()

  return encryptedData
}

const ascii_to_hex = (str) => {
  var arr1 = []
  for (var n = 0, l = str?.toString().length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16)
    arr1.push(hex)
  }
  return arr1.join("")
}

export const decrypt = (value, token = defaultKey, vector = defaultIV) => {
  //convert hex to base64
  const encryptedHex = CryptoJS.enc.Hex.parse(value)
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedHex)

  //decrypt base64
  let key = CryptoJS.enc.Hex.parse(ascii_to_hex(token))
  let initialVector = CryptoJS.enc.Hex.parse(ascii_to_hex(vector))
  let decryptedData = CryptoJS.AES.decrypt(encryptedBase64, key, {
    iv: initialVector,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  }).toString(CryptoJS.enc.Utf8)

  return decryptedData
}
