// images import
import Logo from "./images/Logo.png"
import MastercardAlt from "./images/mastercard.png"
import BannerImage from "./images/BannerImage.png"
import FeaturesImage from "./images/FeaturesImage.png"
import ResetPassword from "./images/ResetPassword.png"
import onboardingPageImage from "./images/onboardingPageImage.png"
import error from "./images/404-error.jpg"

// icons import
import Nigeria from "./icons/Nigeria.svg"
import UnitedStates from "./icons/UnitedStates.svg"
import cardDesign from "./icons/design.svg"
import { ReactComponent as LogOutIcon } from "./icons/log out.svg"
import { ReactComponent as GrowthIcon } from "./icons/growth.svg"
import { ReactComponent as PiggyIcon } from "./icons/piggy.svg"
import { ReactComponent as HandIcon } from "./icons/hand.svg"
import { ReactComponent as MastercardLogo } from "./icons/mastercard-logo.svg"
import { ReactComponent as MastercardAltLogo } from "./icons/mastercard-alt.svg"
import { ReactComponent as PayIcon } from "./icons/pay.svg"
import { ReactComponent as SettingsIcon } from "./icons/settings.svg"
import { ReactComponent as LightHandIcon } from "./icons/light-hand.svg"
import { ReactComponent as Mastercard } from "./icons/mastercard.svg"
import { ReactComponent as Visa } from "./icons/visa.svg"
import { ReactComponent as Verve } from "./icons/verve.svg"
import { ReactComponent as CreateSuccessIcon } from "./icons/createSuccessIcon.svg"
import { ReactComponent as CreateFailureIcon } from "./icons/createFailureIcon.svg"
import { ReactComponent as EnterPinIcon } from "./icons/enterPinIcon.svg"
import { ReactComponent as LeftArrow } from "./icons/left-arrow.svg"
import { ReactComponent as FCMB } from "./icons/FCMB.svg"
import { ReactComponent as INestIcon } from "./icons/carIcon.svg"
import { ReactComponent as BankIcon } from "./icons/bankIcon.svg"

export const IMAGES = {
  Logo,
  BannerImage,
  FeaturesImage,
  onboardingPageImage,
  ResetPassword,
  error,
  MastercardAlt,
}

export const ICONS = {
  Nigeria,
  UnitedStates,
  LogOutIcon,
  GrowthIcon,
  PiggyIcon,
  HandIcon,
  MastercardLogo,
  PayIcon,
  SettingsIcon,
  LightHandIcon,
  Mastercard,
  Visa,
  Verve,
  CreateFailureIcon,
  CreateSuccessIcon,
  EnterPinIcon,
  LeftArrow,
  FCMB,
  cardDesign,
  MastercardAltLogo,
  BankIcon,
  INestIcon,
}
