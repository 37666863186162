import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { userAuthActions } from "../services/reducers/userAuth.reducer"
import { systemControllersActions } from "../services/reducers/system.reducer"

const useRedirect = () => {
  const dispatch = useDispatch()

  const redirect = useCallback(async () => {
    localStorage.clear()
    dispatch(userAuthActions.logout())
    dispatch(systemControllersActions.clear())
  }, [dispatch])

  return redirect
}

export default useRedirect
