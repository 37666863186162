import { toast } from "react-toastify"
import { decrypt, encrypt } from "./security"

const key = process.env.REACT_APP_ENCRYPTION_KEY
const iv = process.env.REACT_APP_ENCRYPTION_IV

export const generateRequestId = () => {
  const newId = (
    Date.now().toString(36).substr(2, 9) + Math.random().toString(36).substr(2, 10)
  ).toUpperCase()

  return newId
}

export const alertError = (message) => {
  toast.error(message || "An error occurred")
}

export const renderMinutes = (seconds) => {
  let min = `${Math.floor(seconds / 60)}`
  let sec = `${seconds - min * 60}`
  min = min?.length < 2 ? `0${min}` : min
  sec = sec?.length < 2 ? `0${sec}` : sec
  return `${min}:${sec}s`
}

export const trimURL = (url) => {
  const trim = url?.toLowerCase()?.replaceAll(" ", "")
  return trim
}

export const uniqueArray = (arr) =>
  arr?.length > 0
    ? arr?.filter(
        (object, index) =>
          index === arr?.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(object)),
      )
    : []

export function stringToColor(string) {
  let hash = 0
  let i

  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

export function stringAvatar(identifier, type) {
  if (!identifier) return ""

  if (type === "name") {
    const nameParts = identifier.split(" ").filter(Boolean)
    const firstInitial = nameParts[0]?.[0] || ""
    const secondInitial = nameParts[1]?.[0] || ""
    return `${firstInitial}${secondInitial}`.toUpperCase()
  } else {
    return identifier[0]?.toUpperCase() || ""
  }
}

export const appCurrency = (currency) => {
  let value
  switch (currency?.toUpperCase()) {
    case "NGN":
      value = { currency: "NGN", symbol: "₦" }
      break
    case "USD":
      value = { currency: "USD", symbol: "$" }
      break
    default:
      value = { currency: "NGN", symbol: "₦" }
      break
  }
  return value
}
export const getStorageItem = (storeKey) => {
  let storageItem = localStorage.getItem(storeKey)
  if (storageItem) {
    storageItem = JSON.parse(decrypt(JSON.parse(storageItem), key, iv))
  }
  return storageItem
}

export const setStorageItem = (storeKey, value) => {
  localStorage.setItem(storeKey, JSON.stringify(encrypt(value, key, iv)))
}

export const removeStorageItem = (storeKey) => {
  localStorage.removeItem(storeKey)
}

export const filterActiveInvestments = (investments) =>
  investments.filter((investment) =>
    investment?.nonFcmbDomiciliaryPayout
      ? investment?.runningStatus?.toLowerCase() === "active"
      : investment.status?.toLowerCase() === "active",
  )

export const filterInactiveInvestments = (investments) =>
  investments.filter((investment) =>
    investment?.nonFcmbDomiciliaryPayout
      ? investment?.runningStatus?.toLowerCase() !== "active"
      : investment.status?.toLowerCase() !== "active",
  )

export const isActive = (data) =>
  data?.nonFcmbDomiciliaryPayout
    ? data?.runningStatus?.toLowerCase() === "active"
    : data?.status?.toLowerCase()

export const numberWithCommas = (number) => {
  let response = number
  if (typeof number === "string") {
    response = Number(response)
  }
  return response?.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
