import { Suspense } from "react"
import ReactGA from "react-ga"
import { useSelector } from "react-redux"
import TagManager from "react-gtm-module"
import { useIdleTimer } from "react-idle-timer"
import { Box, CircularProgress, CssBaseline, ThemeProvider } from "@mui/material"

import theme from "./utils/Theme"
import AppRoutes from "./routes/Routes"
import useLogout from "./hooks/useLogout"
import { getStorageItem } from "./utils/helperFunctions"

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
}

TagManager.initialize(tagManagerArgs)

ReactGA.initialize(process.env.REACT_APP_GA_ID)

const Spinner = () => {
  return (
    <Box className=" flex flex-col w-screen h-screen justify-center items-center ">
      <CircularProgress color="secondary" />
    </Box>
  )
}

function App() {
  const isLoggedIn = useSelector((state) => state?.userAuth?.isLoggedIn)
  const logoutUser = useLogout()

  const onIdle = () => {
    const isPayment = getStorageItem("isPayment")
    if (isLoggedIn && !isPayment) {
      logoutUser()
    }
  }

  useIdleTimer({
    timeout: 5 * 60 * 1000,
    onIdle: onIdle,
    debounce: 0,
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Spinner />}>
        <AppRoutes />
      </Suspense>
    </ThemeProvider>
  )
}

export default App
