import axios from "axios"
import store from "../store"
import { useEffect, useRef } from "react"
import useRedirect from "../hooks/useRedirect"
import { generateHeader } from "../utils/security"
import { refreshAccessToken } from "../services/actions/authentication.actions"

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + "/api",
})

Axios.interceptors.request.use(
  (request) => {
    const isLoggedIn = store.getState().userAuth.isLoggedIn
    const token = store.getState().userAuth?.data?.token

    let req = request

    if (!navigator.onLine) {
      throw new Error("No Internet Connection")
    }

    if (isLoggedIn) {
      req.headers = {
        ...req.headers,
        ...generateHeader(),
        Authorization: `Bearer ${token}`,
      }
    } else {
      req.headers = { ...req.headers, ...generateHeader() }
      delete req.headers["Authorization"]
    }

    return req
  },
  (error) => {
    throw new Error(error.response?.data?.responseMessage || "An error occurred")
  },
)

const AxiosInterceptor = ({ children }) => {
  const redirect = useRedirect()
  const isRefreshing = useRef(false)
  const failedQueue = useRef([])

  const processQueue = (error, token = null) => {
    failedQueue.current.forEach(prom => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })
    failedQueue.current = []
  }

  useEffect(() => {
    const resInterceptor = (response) => {
      return response
    }

    const resErrInterceptor = async (error) => {
      const originalRequest = error.config
      if (!!error?.response?.data) {
        if (originalRequest.url === "/OnBoarding/LoginUser") {
          return Promise.reject(error.response?.data)
        }
        if (
          error?.response?.status === 401 &&
          Number(error.response?.data?.responseCode) === 318 // 318 indicates 401 caused by invalid token
        ) {
          if (!isRefreshing.current) {
            isRefreshing.current = true
            try {
              await refreshAccessToken()
              const newToken = store.getState().userAuth?.data?.token
              processQueue(null, newToken)
              return Axios(originalRequest)
            } catch (refreshError) {
              processQueue(refreshError, null)
              redirect()
              throw new Error(refreshError.response?.data?.responseMessage || "An error occurred")
            } finally {
              isRefreshing.current = false
            }
          } else {
            return new Promise((resolve, reject) => {
              failedQueue.current.push({ resolve, reject })
            }).then(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token
              return Axios(originalRequest)
            }).catch(err => {
              return Promise.reject(err)
            })
          }
        }
      }

      throw new Error(error.response?.data?.responseMessage || error.message)
    }

    const responseInterceptor = Axios.interceptors.response.use(resInterceptor, resErrInterceptor)

    return () => Axios.interceptors.response.eject(responseInterceptor)
  }, [redirect])

  return children
}

export default Axios
export { AxiosInterceptor }
